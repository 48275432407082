import { useAssignmentsState } from 'components/pages/AssignmentsPage/index.state'
import { TABS } from 'components/pages/AssignmentsPage/useNav'
import SelectChildren from 'components/pages/StudentPage/AccountManagersSection/components/SelectChildren'
import RatioBox from 'components/UI/RatioBox'
import moment from 'moment'
import { Button, DatePicker, IconButton, Send } from 'nzk-react-components'
import { getLuminance } from 'polished'
import React, { useMemo, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'
import config from '../../../../../../config'

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 0 20px;
  max-width: 1060px;
  margin: 0 auto;
`

const Header = styled.div`
  position: relative;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 10px;
  text-align: center;
  margin: 30px 0;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`

const Box = styled.div`
  background-color: #341644;
  box-shadow: 0 -3px 0 #531D75, 0 6px 0 rgba(0,0,0,0.4);
  padding: 10px 20px;
  border-radius: 11px;

`

const Field = styled.div`
  > .lesson-image {
    border-radius: 11px;
    overflow: hidden;
    position: relative;
  }
  > .lesson-image img {
    width: 100%;
    height: 100%;
  }
  > .field-title {
    font-family: 'Rammetto One';
    font-size: 13px;
    margin-bottom: 6px;
  }
  > .lesson-title {
    font-size: 21px;
  }
  margin-bottom: 10px;
`


const SelectChildrenWrapper = styled.div`
  max-width: 500px;
  .wrapper {
    background-color: inherit;
    > * {
      color: #fff;
      font-family: 'Rammetto One';
    }
  }
  .content {
    background-color: rgba(255, 255, 255, 0.1);
    > * {
      font-size: 10px;
      color: #fff;
    }
  }
`

const DatePickerWrapper = styled.div`
  position: relative;
  .react-datepicker__portal {
    position: absolute;
    z-index: 10;
    bottom: 54px;
  }
  .react-datepicker__day-names {
    background-color: #1A0425;
  }
`

const Actions = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  > * { margin-right: 20px }
  > :last-child { margin-right: 0; }
`

const Error = styled.div`
  color: red;
  font-size: 13px;
  margin-bottom: 15px;
`

const LessonTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'Rammetto One';
  font-size: 11px;
  > * { margin-right: 8px; margin-bottom: 8px; padding: 0 4px; }
`

const LessonTag = styled.div`
  background-color: ${(props: { backgroundColor: string, color: string }) => props.backgroundColor};
  color: ${(props: { backgroundColor: string, color: string }) => props.color};
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
  padding: 0 8px;
`

const PreviewButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SendAssignmentButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`


interface FormData {
  lessonId: string
  zookeeperIds: string[]
  dueDate: Date | null
}

interface EditingFormData {
  zookeeperIds: string[]
  dueDate: Date | null
}


interface EditingData {
  zookeepers: any[]
  dueDate: Date | null
}

interface IProps {
  lesson: {
    _id: string
    title: string
    backgroundUrl: string
    tags: {
      _id: string
      color: string
      value: string
    }[]
  }
  editing?: boolean
  _id?: string
  formData?: EditingData
  onSubmit?: (_id: string, data: EditingFormData) => Promise<void>
  dismiss?: () => void
}

const CreateLessonAssignment = (props: IProps) => {
  const { currentUser } = useCurrentUserState()
  const { navHook, createAssignmentGroup, creatingAssignmnentGroup } = useAssignmentsState()
  const [zookeepers, setZookeepers] = useState<any[]>(props.formData?.zookeepers || [])
  const [dueDate, setDueDate] = useState<Date | null>(props.formData?.dueDate ? new Date(props.formData.dueDate) : moment().add(7, 'days').toDate())
  const [errors, setErrors] = useState<any>({})

  const formData: FormData = useMemo(() => {
    return {
      lessonId: props.lesson._id,
      zookeeperIds: zookeepers.map(d => d._id),
      dueDate
    }
  }, [zookeepers, dueDate])

  const canSubmit = useMemo(() => {
    if (creatingAssignmnentGroup) return false
    if (formData.zookeeperIds.length === 0) return false
    if (!formData.dueDate || formData.dueDate < new Date()) return false
    if (!formData.lessonId) return false
    return true
  }, [formData, errors, creatingAssignmnentGroup])

  const onDateChange = (date: Date) => {
    setErrors(e => ({ ...e, dueDate: null }))
    date.setHours(23, 59, 50, 999)
    if (date < new Date()) {
      setErrors(e => ({ ...e, dueDate: 'Due date cannot be in the past.' }))
    }
    setDueDate(new Date(date))
  }

  const onSubmit = async () => {
    if (!canSubmit) return
    if (props.onSubmit) {
      props.onSubmit(props._id!, formData)
    } else {
      await createAssignmentGroup(formData)
      navHook.changeTab(TABS.ASSIGNMENTS)
    }
  }

  const onPreview = () => {
    window.open(`${config.eduRoot}/edu/zoo/lessons-v2/${props.lesson._id}?preview=true`)
  }

  const saveLabel = useMemo(() => {
    if (creatingAssignmnentGroup) return 'Loading...'
    if (props.editing) return 'Save'
    return 'Send'
  }, [creatingAssignmnentGroup])

  return <Wrapper>
    <Header>
      <Title>Set Lesson Assignment</Title>
      <SendAssignmentButton>
        <IconButton theme='confirm' size='small' onClick={onSubmit} disabled={!canSubmit} icon={<Send />}>
          {saveLabel}
        </IconButton>
      </SendAssignmentButton>
    </Header>
    <Content>
      <Box>
        <Field>
          <div className='field-title'>Assignment Title</div>
          <div className='lesson-title'>{props.lesson.title}</div>
        </Field>
        <Field>
          <div className='field-title'>Assignment Image</div>
          <div className='lesson-image'>
            <RatioBox ratio='16:9'>
              <img src={props.lesson.backgroundUrl} alt={props.lesson.title} />
              <PreviewButton>
                <Button theme='primary' size='regular' onClick={onPreview}>
                  Preview
                </Button>
              </PreviewButton>
            </RatioBox>
          </div>
        </Field>
        <Field>
          <div className='field-title'>Tags</div>
          <LessonTags>
            { (props.lesson.tags || []).map(t => <LessonTag key={t._id} backgroundColor={t.color} color={getLuminance(t.color) > .5 ? '#000' : '#fff'}>{t.value}</LessonTag>)}
          </LessonTags>
        </Field>
      </Box>
      <Box>
        <Field>
          <div className='field-title'>Assign to:</div>
          <SelectChildrenWrapper>
            <SelectChildren childrenAccounts={currentUser?.children || []} onChange={(zookeepers) => setZookeepers(zookeepers)} defaultSelectedChildren={zookeepers.map(d => d._id)} />
          </SelectChildrenWrapper>
        </Field>
        <Field>
          <div className='field-title'>
            Due
          </div>
          { errors.dueDate && <Error>{errors.dueDate}</Error> }
          <DatePickerWrapper>
            <DatePicker primary='#492E57' defaultValue={dueDate || undefined} onChange={(d) => onDateChange(d)} />
          </DatePickerWrapper>
        </Field>
      </Box>
    </Content>
    <Actions>
      { props.dismiss && props.editing && <Button size='small' theme='primary' onClick={props.dismiss} disabled>
          Cancel
      </Button> }
      <IconButton size='small' theme='confirm' onClick={onSubmit} disabled={!canSubmit} icon={<Send />}>
        { saveLabel }
      </IconButton>
    </Actions>
  </Wrapper>
}


CreateLessonAssignment.defaultProps = {
  editing: false,
  _id: null,
  formData: null,
  onSubmit: null,
  dismiss: null,
}

export default CreateLessonAssignment
