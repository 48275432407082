export const writingTypes = [
  {
    id: 'free-write',
    title: 'Free Write',
    image: `free-write.png`
  },
  {
    id: 'adventure-story',
    title: 'Story',
    image: 'adventure-story.png'
  },
  {
    id: 'newspaper-article',
    title: 'Newspaper Article',
    image: 'newspaper-article.png'
  },
  {
    id: 'report',
    title: 'Report',
    image: 'report.png'
  },
  {
    id: 'poem',
    title: 'Poem',
    image: 'poetry.png'
  },
  {
    id: 'instructions',
    title: 'Instructions',
    image: 'instructions.png'
  },
  {
    id: 'letter-writing',
    title: 'Letter Writing',
    image: 'letter-writing.png'
  },
  {
    id: 'persuasive-writing',
    title: 'Persuasive Writing',
    image: 'persuasive-writing.png'
  },
  {
    id: 'diary-entry',
    title: 'Diary Entry',
    image: 'diary-entry.png'
  },
  // {
  //   id: 'explanation',
  //   title: 'Explanation',
  //   image: 'explanation.png'
  // },
  {
    id: 'playscript',
    title: 'Playscript',
    image: 'playscript.png'
  },
  {
    id: 'biography',
    title: 'Biography',
    image: 'biography.png'
  },
  // {
  //   id: 'upload',
  //   title: 'Upload',
  //   image: `free-write.png`
  // }
]

export default {}
