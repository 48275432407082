import { ArrowLeft, ArrowRight, Button } from 'nzk-react-components'
import { getLuminance } from 'polished'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 60px;
`

const ScrollContent = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
  > * {
    margin-right: 20px;
  }
`

const Lesson = styled.div`
  --height: 180px;
  min-width: calc(16 / 9 * var(--height));
  max-width: calc(16 / 9 * var(--height));
  background-color: #341644;
  box-shadow: 0 5px 0 rgba(0,0,0, 0.4);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

`

const LessonTitle = styled.div`
  font-family: 'Rammetto One';
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
`

const Thumbnail = styled.div`
  position: relative;
  min-height: 200px;
  width: calc(16 / 9 * var(--height));
    height: var(--height);
  > * {
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0 !important;
  }
`

const ThumbnailOverlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  > :first-child {
    margin-bottom: 20px;
    font-size: 21px;
    text-align: center;
  }
`

const LessonInfos = styled.div`
  margin-top: 5px;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`


const LessonTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * { margin-right: 8px; margin-bottom: 8px; padding: 0 4px; font-weight: bold; }
`

const LessonTag = styled.div`
  background-color: ${(props: { backgroundColor: string, color: string }) => props.backgroundColor};
  color: ${(props: { backgroundColor: string, color: string }) => props.color};
  border-radius: 50px;
  padding: 0 8px;
  font-family: 'Rammetto One';
  font-size: 11px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0;  }
`

const ScrollButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin: 0 !important;
`

const ScrollLeftButton = styled(ScrollButton)`
  left: 0;
`

const ScrollRightButton = styled(ScrollButton)`
  right: 0;
`


interface IProps {
  lessons: {
    _id: string
    title: string
    backgroundUrl: string
    tags: {
      _id: string
      color: string
      value: string
    }[]
  }[]
  preview: (l: any) => void
  onLessonSelected: (l: any) => void
}

const LessonsRow = (props: IProps) => {
  const [scrollLeftPx, setScrollLeftPx] = useState(0)
  const [scrollingEnabled, setScrollingEnabled] = useState(false)

  const scrollDivRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const onScroll = (e) => {
      setScrollLeftPx(e.target.scrollLeft)
    }
    const debounceOnScroll = debounce(onScroll, 500)
    if (scrollDivRef.current && scrollDivRef.current.scrollWidth > scrollDivRef.current.clientWidth) {
      setScrollingEnabled(true)
    }

    scrollDivRef.current?.addEventListener('scroll', debounceOnScroll)
    return () => scrollDivRef.current?.removeEventListener('scroll', debounceOnScroll)
  }, [])

  const canScrollLeft = useMemo(() => {
    return scrollLeftPx > 0
  }, [scrollLeftPx])

  const canScrollRight = useMemo(() => {
    if (!scrollDivRef.current) return true
    return scrollLeftPx < scrollDivRef.current.scrollWidth - scrollDivRef.current.clientWidth
  }, [scrollLeftPx])

  const scrollRight = () => {
    if (!scrollDivRef.current || !canScrollRight) return
    scrollDivRef.current.scrollBy({ left: scrollDivRef.current?.clientWidth / 2, behavior: 'smooth' })
  }

  const scrollLeft = () => {
    if (!scrollDivRef.current || !canScrollLeft) return
    scrollDivRef.current.scrollBy({ left: -scrollDivRef.current?.clientWidth / 2, behavior: 'smooth' })
  }

  return <Wrapper>
    { scrollingEnabled && <ScrollLeftButton>
      <Button size='regular' round theme='primary' disabled={!canScrollLeft} onClick={scrollLeft}>
        <ArrowLeft />
      </Button>
    </ScrollLeftButton> }
    <ScrollContent ref={scrollDivRef}>
    {props.lessons.map(l => <Lesson key={l._id}>
    <Thumbnail>
      <img src={l.backgroundUrl} alt={`${l.title}`} />
      <ThumbnailOverlay>
        <LessonTitle>{l.title}</LessonTitle>
        <LessonTags>
          { (l.tags || []).map(t =>
            <LessonTag
              key={t._id}
              backgroundColor={t.color}
              color={getLuminance(t.color) > .5 ? '#000' : '#fff'}
            >
              {t.value}
            </LessonTag>
          )}
        </LessonTags>
      </ThumbnailOverlay>
    </Thumbnail>
    <LessonInfos>
      <Actions>
        <Button theme='primary' size='x-small' onClick={() => props.preview(l)}>Preview</Button>
        <Button theme='confirm' size='small' onClick={() => props.onLessonSelected(l)}>Select</Button>
      </Actions>
    </LessonInfos>
  </Lesson>)}
  </ScrollContent>
  { scrollingEnabled && <ScrollRightButton>
    <Button size='regular' round theme='primary' disabled={!canScrollRight} onClick={scrollRight}>
      <ArrowRight />
    </Button>
  </ScrollRightButton> }
  </Wrapper>
}

export default LessonsRow
