import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useEffect, useState } from 'react'

export type ITab = 'ASSIGNMENTS' | 'LESSONS' | 'WRITING'

export const TABS = {
  ASSIGNMENTS: 'ASSIGNMENTS' as ITab,
  LESSONS: 'LESSONS' as ITab,
  WRITING: 'WRITING' as ITab,
}

const useNav = () => {
  const location = useLocation()
  const [tab, setTab] = useState(TABS.ASSIGNMENTS)

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const tab = urlParams.get('tab')
    setTab((tab as ITab) || TABS.ASSIGNMENTS)
  }, [location])

  const changeTab = (tab: ITab) => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('tab', tab)
    navigate(`${location.pathname}?${urlParams.toString()}`)
  }
  return { tab, changeTab }
}

export default useNav
