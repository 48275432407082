import Loader from 'components/UI/Loader'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import { useAssignmentsState } from '../../index.state'
import { TABS } from '../../useNav'
import LessonAssignment from './components/LessonAssignment'
import UpdateLessonAssignment from './components/UpdateLessonAssignment'
import UpdateWritingAssignment from './components/UpdateWritingAssignment'
import WritingAssignment from './components/WritingAssignment'

const Wrapper = styled.div`
  margin-top: 40px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  clear: both;
`

const EmptyWrapper = styled(Wrapper)`
  text-align: center;
  max-width: 860px;
  margin: 40px auto 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 400px);
  p {
    line-height: 1.4;
  }
`

const Title = styled.div`
  font-family: 'Rammetto One';
  font-size: 24px;
  margin-bottom: 30px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  > * { margin-right: 15px; }
`


const Assignment = (a) => {
  if (a.lesson) return <LessonAssignment {...a} />
  if (a.writingPrompt) return <WritingAssignment {...a} />
  return null
}

export default () => {
  const { assignmentGroups, loadingAssignmentGroupsPage, hasMoreAssignmentGroups, fetchNextAssignmentGroupsPage, navHook } = useAssignmentsState()
  const [editing, setEditing] = useState<any>(null)

  if (editing && editing.lesson) return <UpdateLessonAssignment {...editing} dismiss={() => setEditing(null)} />
  if (editing && editing.writingPrompt) return <UpdateWritingAssignment {...editing} dismiss={() => setEditing(null)} />
  
  if (!hasMoreAssignmentGroups && assignmentGroups.length === 0) {
    return <EmptyWrapper>
      <Title>You haven't sent any assignments</Title>
      <p>Assignments are tasks that you can send your child, such as a writing prompt, or a lesson from our Night Zookeeper interactive lesson series.</p>
      <Actions>
        <Button theme='primary' size='small' onClick={() => navHook.changeTab(TABS.WRITING)}>Send a writing assignment</Button>
        <div>Or</div>
        <Button theme='primary' size='small' onClick={() => navHook.changeTab(TABS.LESSONS)}>Send a lesson assignment</Button>
      </Actions>
    </EmptyWrapper>
  }
  return <Wrapper>
    <Actions>
      <Button theme='primary' size='small' onClick={() => navHook.changeTab(TABS.WRITING)}>Send a writing assignment</Button>
      <div>Or</div>
      <Button theme='primary' size='small' onClick={() => navHook.changeTab(TABS.LESSONS)}>Send a lesson assignment</Button>
    </Actions>
    <InfiniteScroll
        pageStart={0}
        loadMore={fetchNextAssignmentGroupsPage}
        hasMore={!loadingAssignmentGroupsPage && hasMoreAssignmentGroups}
        loader={<LoaderWrapper key='loader'><Loader color='#fff' /></LoaderWrapper>}
      >
    {assignmentGroups.map(d => <Assignment key={d._id} {...d} onEdit={() => setEditing(d)} />)}
    </InfiniteScroll>
  </Wrapper>
}
