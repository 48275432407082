import React from 'react'
import Assignment from './Assignment'

interface IProps {
  _id: string
  dueDate: Date
  lesson: {
    _id: string
    title: string
    backgroundUrl: string
  },
  assignments: {
    _id: string
    started: boolean
    completed: boolean
    zookeeper: {
      _id: string
      username: string
      avatar: {
        url: string
      }
    }
  }[]
  zookeepers: {
    _id: string
    username: string
    avatar: {
      url: string
    }
  }[]
  onEdit: () => void
}

const LessonAssignment = (props: IProps) => {
  return <Assignment
    _id={props._id}
    backgroundUrl={props.lesson.backgroundUrl}
    dueDate={props.dueDate}
    title={props.lesson.title}
    assignments={props.assignments}
    zookeepers={props.zookeepers}
    onEdit={props.onEdit}
  /> 
}

export default LessonAssignment
