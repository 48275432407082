import WritingAssignmentImage from 'components/pages/AssignmentsPage/components/WritingAssignmentImage'
import { useAssignmentsState } from 'components/pages/AssignmentsPage/index.state'
import { Avatar, Button, Pencil, TrashWhite } from 'nzk-react-components'
import React, { useMemo } from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #391450;
  box-shadow: 0 -3px 0 #531D75, 0 5px 0 rgba(0,0,0,0.4);
  border-radius: 12px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 15px auto;
  max-width: 800px;

  .title {
    font-family: 'Rammetto One';
  }
  > * {
    margin-right: 20px;
  }
`

const Column = styled.div`
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
`

const Thumbnail = styled.div`
  height: 130px;
  width: 230px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 100%;
  }
`

const Avatars = styled.div`
  display: grid;
  grid-template-columns: 30px 30px 30px;
  grid-gap: 5px;
`

const Actions = styled.div`
  > * { margin-right: 8px; }
  > :last-child { margin-right: 0; }
`

interface IProps {
  _id: string
  dueDate: Date
  title: string
  backgroundUrl: string
  writingType?: string
  assignments: {
    _id: string
    started: boolean
    completed: boolean
    zookeeper: {
      _id: string
      username: string
      avatar: {
        url: string
      }
    }
  }[]
  zookeepers: {
    _id: string
    username: string
    avatar: {
      url: string
    }
  }[]
  onEdit: () => void
}

const Assignment = (props: IProps) => {
  const { onArchiveAssignmentGroup } = useAssignmentsState()

  const { completed, started, notStarted } = useMemo(() => {
    const DEFAULT = { completed: [], started: [], notStarted: [] }
    if (!props.assignments) return DEFAULT
    return (props.assignments || []).reduce((acc: any, assignment) => {
      if (assignment.completed) acc.completed = [...acc.completed, assignment]
      else if (assignment.started) acc.started = [...acc.started, assignment]
      else acc.notStarted = [...acc.notStarted, assignment]
      return acc
    }, DEFAULT)
  }, [props])

  const pluralize = (singular, nb, plural?: string) => {
    if (nb <= 1) return singular
    return plural || `${singular}s`
  }

  return <Wrapper>
    <Column>
      <div className='title'>Assignment</div>
      { props.backgroundUrl && <Thumbnail>
        <img src={props.backgroundUrl} alt={props.title} />
      </Thumbnail> }
      { !props.backgroundUrl && props.writingType && <WritingAssignmentImage writingType={props.writingType || 'free-write'} /> }
      <div>{props.title}</div>
    </Column>
    <Column>
      <div className='title'>Due:</div>
      <div>
        { props.dueDate ? <FormattedDate value={props.dueDate} month='long'
          year='numeric'
          day='numeric'
        /> : 'No due date' }
      </div>
    </Column>
    { (started.length > 0 || completed.length > 0 || notStarted.length > 0 ) ? <Column>
      <div className='title'>Not started by:</div>
      <div>
        {notStarted.length} {pluralize('student', notStarted.length)}
      </div>
      <Avatars>
        {notStarted.map(({ zookeeper: z }) => <Avatar key={z._id} size='30px' user={{ ...z, type: 'student' }} />)}
      </Avatars>
      <div className='title'>Started by:</div>
      <div>
        {started.length} {pluralize('student', started.length)}
      </div>
      <Avatars>
        {started.map(({ zookeeper: z }) => <Avatar key={z._id} size='30px' user={{ ...z, type: 'student' }} />)}
      </Avatars>
      <div className='title'>Completed by:</div>
      <div>
        {completed.length} {pluralize('student', completed.length)}
      </div>
      <Avatars>
        {completed.map(({ zookeeper: z }) => <Avatar key={z._id} size='30px' user={{ ...z, type: 'student' }} />)}
      </Avatars>
    </Column> : <Column>
      <div className='title'>Sent to:</div>
      <div>
        {props.zookeepers.length} {pluralize('student', props.zookeepers.length)}
      </div>
      <Avatars>
        {props.zookeepers.map(z => <Avatar key={z._id} size='30px' user={{ ...z, type: 'student' }} />)}
      </Avatars>
    </Column> }
    <Column>
      <Actions>
        <Button theme='red' size='small' round onClick={() => onArchiveAssignmentGroup({ _id: props._id })}>
          <TrashWhite />
        </Button>
        { props.onEdit && <Button theme='orange' size='small' round onClick={() => props.onEdit()}>
          <Pencil />
        </Button>
        }
      </Actions>    
    </Column>
  </Wrapper>
}

Assignment.defaultProps = {
  writingType: null
}

export default Assignment
