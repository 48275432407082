import { gql, useQuery } from '@apollo/client'
import { Link } from 'gatsby'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #ff0;
  border-bottom: 4px solid #d3c100;
  color: #000;
  padding: 20px 20px;
  text-align: center;
  cursor: pointer;
  h3 {
    font-family: 'Rammetto One';
    margin: 0;
    margin-bottom: 10px;
    text-shadow: 0 2px 0 rgba(0,0,0,0.3);
  }
  p {
    margin: 0;
    font-weight: bold;
  }
  font-size: 15px;
`

const StarTutoringAd = () => {
  const { loading, data } = useQuery(gql`
    query getChildrenSubscribed {
      me {
        _id
        children {
          _id
          starTutoring {
            _id
          }
        }
      }
    }
  `)

  const children = useMemo(() => {
    // return [...(data?.me.children || []), ...(data?.me.children || []), ...(data?.me.children || [])]
    return data?.me.children || []
  }, [data])

  const hasAtLeastOneSubscription = useMemo(() => {
    return children.filter(c => c.starTutoring?._id).length > 0
  }, [children])

  if (loading || hasAtLeastOneSubscription) return null
  return <Link to='/advanced-tutoring'>
    <Wrapper>
      <h3>Advanced Tutoring</h3>
      <p>
        Learn how Advanced Tutoring can help your child.
      </p>
    </Wrapper>
  </Link>
}

export default StarTutoringAd
