import WritingAssignmentImage from 'components/pages/AssignmentsPage/components/WritingAssignmentImage'
import { writingTypes } from 'components/pages/AssignmentsPage/constants'
import { useAssignmentsState } from 'components/pages/AssignmentsPage/index.state'
import { TABS } from 'components/pages/AssignmentsPage/useNav'
import SelectChildren from 'components/pages/StudentPage/AccountManagersSection/components/SelectChildren'
import Select from 'components/UI/Select'
import moment from 'moment'
import { Button, DatePicker, IconButton, Send } from 'nzk-react-components'
import React, { useMemo, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 0 20px;
`

const Header = styled.div`
  position: relative;
  @media (max-width: 680px) {
    > :nth-child(2) {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 10px;
  text-align: center;
  margin: 30px 0;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  > :first-child {
    grid-column: 1 / 3;
  }
  > :nth-child(2) {
    grid-column: 3 / 5;
  }
  > :nth-child(3) {
    grid-column: 5 / 7;
  }
  @media (max-width: 1250px) {
    grid-template-rows: 1fr 1fr;
    > :first-child {
      grid-column: 1 / 4;
    }
    > :nth-child(2) {
      grid-column: 4 / 7;
    }
    > :nth-child(3) {
      grid-column: 1 / 7;
    }
  }
  @media (max-width: 700px) {
    grid-template-rows: 1fr 1fr 1fr;
    > :first-child {
      grid-column: 1 / 7;
    }
    > :nth-child(2) {
      grid-column: 1 / 7;
    }
    > :nth-child(3) {
      grid-column: 1 / 7;
    }
  }
`

const Box = styled.div`
  background-color: #341644;
  box-shadow: 0 -3px 0 #531D75, 0 6px 0 rgba(0,0,0,0.4);
  padding: 10px 20px;
  border-radius: 11px;

`

const Field = styled.div`
  > .lesson-image {
    border-radius: 11px;
    overflow: hidden;
    position: relative;
  }
  > .lesson-image img {
    width: 100%;
    height: 100%;
  }
  > .field-title {
    font-family: 'Rammetto One';
    font-size: 13px;
    margin-bottom: 6px;
  }
  margin-bottom: 10px;
`

const TextArea = styled.textarea`
  width: 100%;
  border-radius: 12px;
  resize: none;
  outline: none;
  border: none;
  min-height: 300px;
  padding: 10px;
  font-size: 18px;
  font-family: 'Libre Baskerville';
`

const Input = styled.input`
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: none;
  padding: 8px 10px;
  font-family: 'Libre Baskerville';
  font-size: 18px;
`

const SelectChildrenWrapper = styled.div`
  .wrapper {
    background-color: inherit;
    > * {
      color: #fff;
      font-family: 'Rammetto One';
    }
  }
  .content {
    background-color: rgba(255, 255, 255, 0.1);
    > * {
      font-size: 10px;
      color: #fff;
    }
  }
`

const DatePickerWrapper = styled.div`
  position: relative;
  .react-datepicker__portal {
    position: absolute;
    z-index: 10;
    bottom: 54px;
  }
  .react-datepicker__day-names {
    background-color: #1A0425;
  }
`

const Actions = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  > * { margin-right: 20px }
  > :last-child { margin-right: 0; }
`

const Error = styled.div`
  color: red;
  font-size: 13px;
  margin-bottom: 15px;
`

const SendAssignmentButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

interface WritingPrompt {
  title: string
  description: string
  writingType: string
  image?: string
}

interface FormData {
  writingPrompt: WritingPrompt
  zookeeperIds: string[]
  dueDate: Date | null
}

interface EditingData {
  writingPrompt: WritingPrompt
  zookeepers: any[]
  dueDate: Date | null
}
interface IProps {
  editing?: boolean
  _id?: string
  formData?: EditingData
  onSubmit?: (_id: string, data: FormData) => Promise<void>
  dismiss?: () => void
}


const CreateWritingAssignmnent = (props: IProps) => {
  const { currentUser } = useCurrentUserState()
  const { navHook, createAssignmentGroup, creatingAssignmnentGroup } = useAssignmentsState()
  const [title, _setTitle] = useState(props.formData?.writingPrompt.title || '')
  const [zookeepers, setZookeepers] = useState<any[]>(props.formData?.zookeepers || [])
  const [dueDate, setDueDate] = useState<Date | null>(props.formData?.dueDate ? new Date(props.formData.dueDate) : moment().add(7, 'days').toDate())
  const [errors, setErrors] = useState<any>({})
  const [description, setDescription] = useState(props.formData?.writingPrompt.description || '')
  const [writingType, setWritingType] = useState(props.formData?.writingPrompt.writingType || 'free-write')
  const writingPrompt: WritingPrompt = useMemo(() => {
    return { writingType, title, description }
  }, [writingType, title, description])

  const formData: FormData = useMemo(() => {
    return {
      writingPrompt,
      zookeeperIds: zookeepers.map(d => d._id),
      dueDate
    }
  }, [dueDate, writingPrompt, zookeepers])

  const canSubmit = useMemo(() => {
    if (creatingAssignmnentGroup) return false
    if (formData.zookeeperIds.length === 0) return false
    if (!formData.dueDate || formData.dueDate < new Date()) return false
    if (!formData.writingPrompt.title) return false
    return true
  }, [formData, errors, creatingAssignmnentGroup])

  const onDateChange = (date: Date) => {
    setErrors(e => ({ ...e, dueDate: null }))
    date.setHours(23, 59, 50, 999)
    if (date < new Date()) {
      setErrors(e => ({ ...e, dueDate: 'Due date cannot be in the past.' }))
    }
    setDueDate(new Date(date))
  }

  const onSubmit = async () => {
    if (!canSubmit) return
    if (props.onSubmit) {
      await props.onSubmit(props._id!, formData)
    } else {
      await createAssignmentGroup(formData)
      navHook.changeTab(TABS.ASSIGNMENTS)
    }
  }

  const TITLE_MAX_CHARS = 30

  const setTitle = (v) => {
    if (v.length > TITLE_MAX_CHARS) return
    _setTitle(v)
  }

  const saveLabel = useMemo(() => {
    if (creatingAssignmnentGroup) return 'Loading...'
    if (props.editing) return 'Save'
    return 'Send'
  }, [creatingAssignmnentGroup])

  return <Wrapper>
        <Header>
      <Title>Set Writing Assignment</Title>
      <SendAssignmentButton>
        <IconButton theme='confirm' size='small' onClick={onSubmit} disabled={!canSubmit} icon={<Send />}>
          {saveLabel}
        </IconButton>
      </SendAssignmentButton>
    </Header>
    <Content>
      <Box>
        <Field>
          <div className='field-title'>
            Assignment Title
            <span style={{ float: 'right' }}>{title.length}/{TITLE_MAX_CHARS} max characters</span>
          </div>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target?.value || '')} />
        </Field>
        <Field>
          <div className='field-title'>Writing Type</div>
          <Select options={writingTypes.map(w => ({ value: w.id, label: w.title }))} onChange={(t) => setWritingType(t)} value={writingType} />
        </Field>
        <Field>
          <div className='field-title'>Assignment Image</div>
          <WritingAssignmentImage writingType={writingType} />
        </Field>
      </Box>
      <Box>
        <Field>
          <div className='field-title'>Assignment Description</div>
          <TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
        </Field>
      </Box>
      <Box>
        <Field>
          <div className='field-title'>Assign to:</div>
          <SelectChildrenWrapper>
            <SelectChildren childrenAccounts={currentUser?.children || []} onChange={(zookeepers) => setZookeepers(zookeepers)} defaultSelectedChildren={zookeepers.map(d => d._id)} />
          </SelectChildrenWrapper>
        </Field>
        <Field>
          <div className='field-title'>
            Due
          </div>
          { errors.dueDate && <Error>{errors.dueDate}</Error> }
          <DatePickerWrapper>
            <DatePicker primary='#492E57' defaultValue={dueDate || undefined} onChange={(d) => onDateChange(d)} />
          </DatePickerWrapper>
        </Field>
      </Box>
    </Content>
    <Actions>
      { props.dismiss && props.editing && <Button size='small' theme='primary' onClick={props.dismiss} disabled>
          Cancel
      </Button> }
      <IconButton size='small' theme='confirm' onClick={onSubmit} disabled={!canSubmit} icon={<Send />}>
        { saveLabel }
      </IconButton>
    </Actions>
  </Wrapper>
}

CreateWritingAssignmnent.defaultProps = {
  editing: false,
  _id: null,
  formData: null,
  onSubmit: null,
  dismiss: null,
}

export default CreateWritingAssignmnent
