import { StarWriting, WritingTypeAdventureStory, WritingTypeBiography, WritingTypeDiaryEntry, WritingTypeExplanation, WritingTypeFreeWrite, WritingTypeInstructions, WritingTypeLetterWriting, WritingTypeNewspaperArticle, WritingTypePersuasiveWriting, WritingTypePlayscript, WritingTypePoem, WritingTypeReport } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #231F20;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 230px;
  > :first-child {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #3B3A3B;
    box-shadow: 0 5px 0 rgba(0,0,0,0.4);
  }
`

const iconsMap: any = {
  'star-writing': <StarWriting />,
  'adventure-story': <WritingTypeAdventureStory />,
  'biography': <WritingTypeBiography />,
  'diary-entry': <WritingTypeDiaryEntry />,
  'free-write': <WritingTypeFreeWrite />,
  'instructions': <WritingTypeInstructions />,
  'letter-writing': <WritingTypeLetterWriting />,
  'newspaper-article': <WritingTypeNewspaperArticle />,
  'persuasive-writing': <WritingTypePersuasiveWriting />,
  'playscript': <WritingTypePlayscript />,
  'poem': <WritingTypePoem />,
  'report': <WritingTypeReport />,
  'explanation': <WritingTypeExplanation />
}

const WritingAssignmentImage = ({ writingType }: { writingType: string }) => {
  return <Wrapper>
  <div>
    { React.cloneElement(iconsMap[writingType] || iconsMap['free-write'], { width: '90px', height: '90px' }) }
  </div>
</Wrapper>
}

export default WritingAssignmentImage
