import React from 'react'
import AssignmentsPage from '../components/pages/AssignmentsPage'
import Layout from '../layouts/layout'

const Assignments = () => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <AssignmentsPage />
    </Layout>
  )
}

export default Assignments
