import Loader from 'components/UI/Loader'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import { AgeGroupFilter, useAssignmentsState } from '../../index.state'
import CreateLessonAssignment from './components/CreateLessonAssignment'
import LessonSerie from './components/LessonSerie'

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 140px;
`

const Wrapper = styled.div`
  padding: 0 30px;
  margin-top: 30px;

`

const Series = styled.div`
  > * {
    margin-bottom: 20px;
  }
`

const FiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`

const Filters = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  div {
    background-color: #4E1B6D;
    border-radius: 30px;
    text-align: center;
    padding: 8px;
    font-size: 16px;
    line-height: 1.2;
    font-family: 'Rammetto One';
    cursor: pointer;
    &:hover, &.selected {
      background-color: #9026C1;
    }
  }
  @media screen and (max-width: 840px) {
    div {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 750px) {
    div {
      font-size: 12px;
    }
  }
`

const FilterTitle = styled.div`
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
  @media screen and (max-width: 840px) {
    font-size: 16px;
  }
`

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  text-align: center;
`

const ChallengePreview = styled.img`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 90%;
  border-radius: 30px;
`

const SUBJECTS = [
  { value: 'GENERAL', label: "General"},
  { value: 'STORY_WRITING', label: "Story Writing"},
  { value: 'WRITING_STYLES', label: "Writing Styles"},
  { value: 'VOCABULARY', label: "Vocabulary"},
  { value: 'SPELLING_AND_PUNCTUATION', label: "Spelling & Punctuation"},
  { value: 'READING_COMPREHENSION', label: "Reading Comprehension"},
]

const AGES: { value: AgeGroupFilter, label: string }[] = [
  { value: 'EASY', label: "5-7 year olds" },
  { value: 'MEDIUM', label: "8-10 year olds" },
  { value: 'HARD', label: "11-12+ year olds" },
]

export default () => {
  const { lessonSeries, filterAges, filterSujects, setFilterAges, setFilterSubjects } = useAssignmentsState()
  const [selectedLesson, setSelectedLesson] = useState(null)

  if (selectedLesson) return <CreateLessonAssignment lesson={selectedLesson} />

  const onClickSubjectFilter = (subject: string) => {
    if (filterSujects.includes(subject)) {
      setFilterSubjects(filterSujects.filter(s => s !== subject))
    } else {
      setFilterSubjects([subject])
    }
  }

  const onClickAgeFilter = (ageGroup: AgeGroupFilter) => {
    if (filterAges.includes(ageGroup)) {
      setFilterAges(filterAges.filter(s => s !== ageGroup))
    } else {
      setFilterAges([ageGroup])
    }
  }

  return <Wrapper>
    <FiltersContainer>
      <FilterTitle>Filter by subject</FilterTitle>
      <Filters>{ SUBJECTS.map(s => <div 
        className={filterSujects.includes(s.value) ? 'selected' : ''} 
        onClick={() => onClickSubjectFilter(s.value)} 
        key={s.value}>{s.label}</div>) }</Filters>
      <FilterTitle>Filter by age</FilterTitle>
      <Filters>{ AGES.map(s => <div
        className={filterAges.includes(s.value) ? 'selected' : ''} 
        onClick={() => onClickAgeFilter(s.value)}
        key={s.value.toString()}>{s.label}</div>) }</Filters>
    </FiltersContainer>
    <InfiniteScroll
      pageStart={0}
      aut
      loadMore={lessonSeries.fetchPage}
      hasMore={!lessonSeries.loading && lessonSeries.hasMore}
      loader={<LoaderWrapper key='loader'><Loader color='#fff' /></LoaderWrapper>}
    >
      <Series>
        {lessonSeries.data.map(d => <LessonSerie key={d._id} lessonSerie={d} onLessonSelected={(l) => setSelectedLesson(l)} />)}
      </Series>
    </InfiniteScroll>
    <EmptyWrapper>
      Assignments are primarily for writing lessons. <br />Be sure to have your child visit their learning journey in Activities -&gt; Challenges to improve their {SUBJECTS.find(s => s.value === filterSujects[0])?.label.toLowerCase()} skills.
    </EmptyWrapper> 
    <ChallengePreview src='/images/challanges-preview.png' />
  </Wrapper>
}
