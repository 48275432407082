import { useState } from 'react'

type IReturnHook = [
  loading: boolean,
  hasMore: boolean,
  fetchPage: (page: number) => Promise<any[]>
]

const usePaginatedQuery = (loadPage, pageSize, onNewPage): IReturnHook => {
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)


  const fetchPage = async (page) => {
    if (!hasMore) return
    setLoading(true)
    const res = await loadPage(page - 1, pageSize)
    if (res.length < pageSize) {
      setHasMore(false)
    }
    setLoading(false)
    onNewPage(res)
    return res
  }

  return [loading, hasMore, fetchPage]

}

export default usePaginatedQuery
