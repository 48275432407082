import { useApolloClient } from '@apollo/client'
import { useAsync } from 'nzk-react-components'
import ARCHIVE_ASSIGNMENT_GROUP from './graphql/assignments_archiveGroup.graphql'
import CREATE_ASSIGNMENT_GROUP from './graphql/assignments_createGroup.graphql'
import UPDATE_ASSIGNMENT_GROUP from './graphql/assignments_updateGroup.graphql'
import GET_ASSIGNMENT_GROUPS from './graphql/getAssignmentGroups.graphql'
import GET_SERIES from './graphql/getSeries.graphql'

export interface UpdateAssignmentGroupInput {
  zookeeperIds?: string[]
  dueDate?: Date
  lessonId?: string
  writing?: {
    title: string
    description: string
  }
  archived?: boolean
}

export interface LessonsV2SeriesFiltersInput {
  subjects?: string[]
  yearGroups?: number[]
}

const useData = () => {
  const client = useApolloClient()

  const fetchAssignmentGroupsPage = async (page, pageSize) => {
    const { data } = await client.query({
      query: GET_ASSIGNMENT_GROUPS,
      variables: {
        skip: page * pageSize,
        limit: pageSize
      }
    })
    return data.me.sentAssignmentGroups
  }

  const fetchLessonSeriesPage = async (page, pageSize, filters?: LessonsV2SeriesFiltersInput) => {
    const { data } = await client.query({
      query: GET_SERIES,
      variables: {
        skip: page * pageSize,
        limit: pageSize,
        filters: filters || {}
      }
    })
    return data.lessonV2Series
  }

  const { loading: creatingAssignmnentGroup, execute: createAssignmentGroup } = useAsync({
    asyncFunc: async (params) => {
      const { data } = await client.mutate({
        mutation: CREATE_ASSIGNMENT_GROUP,
        variables: {
          input: params
        }
      })
      return data.assignments_createGroup
    },
    immediate: false
  })

  const { loading: updatingAssignmentGroup, execute: updateAssignmentGroup } = useAsync({
    asyncFunc: async (params: { _id: string, input: UpdateAssignmentGroupInput }) => {
      const { data } = await client.mutate({
        mutation: UPDATE_ASSIGNMENT_GROUP,
        variables: {
          _id: params._id,
          input: params.input,
        }
      })
      return data.assignments_updateGroup
    },
    immediate: false
  })

  const { loading: archivingAssignmentGroup, execute: archiveAssignmentGroup } = useAsync({
    asyncFunc: async (params: { _id: string }) => {
      const { data } = await client.mutate({
        mutation: ARCHIVE_ASSIGNMENT_GROUP,
        variables: {
          _id: params._id
        }
      })
      return data.assignments_archiveGroup
    },
    immediate: false
  })
  

  return {
    fetchAssignmentGroupsPage,
    creatingAssignmnentGroup,
    createAssignmentGroup,
    fetchLessonSeriesPage,
    archivingAssignmentGroup,
    archiveAssignmentGroup,
    updatingAssignmentGroup,
    updateAssignmentGroup,
  }
}

export default useData
