import { useAssignmentsState } from 'components/pages/AssignmentsPage/index.state'
import React from 'react'
import CreateLessonAssignment from '../../Lessons/components/CreateLessonAssignment'

interface IProps {
  _id: string
  dueDate: Date
  lesson: {
    _id: string
    title: string
    backgroundUrl: string
    tags: {
      _id: string
      color: string
      value: string
    }[]
  }
  zookeepers: {
    _id: string
  }[]
  dismiss: () => void
}

const UpdateLessonAssignment = (props: IProps) => {
  const { onUpdateAssignmentGroup } = useAssignmentsState()

  const onSubmit = async (_id: string, data) => {
    await onUpdateAssignmentGroup(_id, data)
    props.dismiss()
  }

  return <CreateLessonAssignment
    lesson={props.lesson}
    editing
    _id={props._id}
    formData={{
      dueDate: props.dueDate,
      zookeepers: props.zookeepers,
    }}
    dismiss={props.dismiss}
    onSubmit={onSubmit}
  />
}

export default UpdateLessonAssignment
