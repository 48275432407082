import useConfirmModal from 'hooks/useConfirmModal'
import usePaginatedQuery from 'hooks/usePaginatedQuery'
import React, { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import useData, { UpdateAssignmentGroupInput } from './useData'
import useNav from './useNav'

export type AgeGroupFilter = 'EASY' | 'MEDIUM' | 'HARD'

const MapAgeGroupFilterToYears = {
  EASY: [0, 1, 2],
  MEDIUM: [3, 4, 5],
  HARD: [6, 7]
}

const AssignmentsPageState = () => {
  const {
    fetchAssignmentGroupsPage,
    fetchLessonSeriesPage,
    createAssignmentGroup: createAssignmentGroupAsync,
    creatingAssignmnentGroup,
    archivingAssignmentGroup,
    archiveAssignmentGroup,
    updateAssignmentGroup,
    updatingAssignmentGroup,
  } = useData()
  const navHook = useNav()

  const [assignmentGroups, setAssignmentGroups] = useState<any[]>([])
  const [lessonSeries, setLessonSeries] = useState<any[]>([])
  const [filterSujects, setFilterSubjects] = useState<string[]>([])
  const [filterAges, setFilterAges] = useState<(AgeGroupFilter[])>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  const [loadingAssignmentGroupsPage, hasMoreAssignmentGroups, fetchNextAssignmentGroupsPage] = usePaginatedQuery(
    fetchAssignmentGroupsPage,
    5,
    (page) => {
    setAssignmentGroups(g => ([...g, ...page]))
  })

  let filters = {}
  if (filterSujects.length) filters = { ...filters, subjects: filterSujects }
  if (filterAges.length) {
    const yearGroups: number[] = []
    if (filterAges.includes('EASY')) yearGroups.push(...MapAgeGroupFilterToYears['EASY'])
    if (filterAges.includes('MEDIUM')) yearGroups.push(...MapAgeGroupFilterToYears['MEDIUM'])
    if (filterAges.includes('HARD')) yearGroups.push(...MapAgeGroupFilterToYears['HARD'])
    filters = { ...filters, yearGroups }
  }

  const fetchLessonSeriesPageLocal = async (p: number, forcePageReset = false) => {
    if (!hasMore && !forcePageReset) return
    setLoading(true)
    const page = !forcePageReset && lessonSeries.length > 0 ? Math.floor(lessonSeries.length/5) : 0
    const res = await fetchLessonSeriesPage(page, 5, filters)
    if (res.length < 5) {
      setHasMore(false)
    }
    setLoading(false)
    if (page === 0) {
      setLessonSeries(res)
    } else {
      setLessonSeries(series => ([...series, ...res]))
    }
    return res
  }

  useEffect(() => {
    fetchLessonSeriesPageLocal(0, true)
    setHasMore(true)
  }, [filterSujects, filterAges])
  
  
  const createAssignmentGroup = async (payload) => {
    const group = await createAssignmentGroupAsync(payload)
    setAssignmentGroups(a => ([group, ...a]))
  }

  const removeAssignmnentGroupUI = (_id) => {
    setAssignmentGroups(a => a.filter(g => g._id !== _id))
  }

  const updateAssignmentGroupUI = (updated) => {
    setAssignmentGroups(a => a.map(group => {
      if (group._id === updated._id) return { ...group, ...updated }
      return group
    }))
  }

  const { confirm: confirmArchiveGroup } = useConfirmModal(
    <>
      <h2>Archive Assignment</h2>
      <p>Are you sure you want to archive this assignment?</p>
    </>
  )
  
  const onArchiveAssignmentGroup = async ({ _id }: { _id: string }) => {
    return new Promise((resolve) => {
      confirmArchiveGroup(async () => {
        if (!_id) return
        if (archivingAssignmentGroup) return
        await archiveAssignmentGroup({ _id })
        removeAssignmnentGroupUI(_id)
        resolve(true)
      }, () => {})
    })

  }

  const onUpdateAssignmentGroup = async (_id: string, input: UpdateAssignmentGroupInput) => {
    if (updatingAssignmentGroup) return
    const updated = await updateAssignmentGroup({ _id, input })
    updateAssignmentGroupUI(updated)
  }

  return {
    navHook,
    assignmentGroups,
    fetchNextAssignmentGroupsPage,
    loadingAssignmentGroupsPage,
    createAssignmentGroup,
    creatingAssignmnentGroup,
    hasMoreAssignmentGroups,
    onArchiveAssignmentGroup,
    onUpdateAssignmentGroup,
    filterAges,
    filterSujects,
    setFilterAges,
    setFilterSubjects,
    lessonSeries: {
      data: lessonSeries,
      loading,
      hasMore,
      fetchPage: fetchLessonSeriesPageLocal
    }
  }
}

const AssignmentsPageStateContainer = createContainer(AssignmentsPageState)

export const AssignmentsPageStateProvider = AssignmentsPageStateContainer.Provider

export const useAssignmentsState = AssignmentsPageStateContainer.useContainer
