import React, { useMemo } from 'react'
import styled from 'styled-components'
import config from '../../../../../../config'
import LessonsRow from './LessonsRow'

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  text-align: center;
`

const Title = styled.div`
  font-family: 'Rammetto One';
  margin-bottom: 10px;
  font-size: 23px;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin-right: 10px;
    margin-bottom: 8px;
    color: yellow;
    font-family: 'Rammetto One';
    font-size: 12px;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 0 8px;
  }
`

const Recommended = styled.div`
  margin-bottom: 10px;
`

interface IProps {
  lessonSerie: {
    name: string
    tags: string[]
    blockedForYearGroups: number[]
    lessons: {
      _id: string
      title: string
      backgroundUrl: string
      tags: {
        _id: string
        color: string
        value: string
      }[]
    }[]
  }
  onLessonSelected: (lesson) => void
}

const LessonSerie = (props: IProps) => {

  const preview = (lesson) => {
    window.open(`${config.eduRoot}/edu/zoo/lessons-v2/${lesson._id}?preview=true`)
  }

  const yearGroupToAge = (yearGroup: number) => {
    const age = yearGroup + 5
    if (age === 12) return `${age}+`
    return `${age}`
  }

  const recommended = useMemo(() => {
    const YEARGROUPS = [0,1,2,3,4,5,6,7]
    const recommendedYearGroups = YEARGROUPS.filter(d => (props.lessonSerie.blockedForYearGroups || []).indexOf(d) < 0)
    return recommendedYearGroups.reduce((acc, y, i) => {
      const age = yearGroupToAge(y)
      if (i === 0) return `${age}`
      if (i === recommendedYearGroups.length - 1) return `${acc} and ${age}.`
      return `${acc}, ${age}`
    }, '')
  }, [])
  
  return <Wrapper>
    <Title>{props.lessonSerie.name}</Title>
    <Recommended>Recommended for ages {recommended}</Recommended>
    <Tags>
      {
        (props.lessonSerie.tags || []).map((t, i) => <div key={i}>{t}</div>)
      }
    </Tags>
    <LessonsRow lessons={props.lessonSerie.lessons} preview={(l) => preview(l)} onLessonSelected={props.onLessonSelected} />
  </Wrapper>
}

export default LessonSerie
