import { useAssignmentsState } from 'components/pages/AssignmentsPage/index.state'
import React from 'react'
import CreateWritingAssignmnent from '../../Writing/components/CreateWritingAssignment'

interface IProps {
  _id: string
  dueDate: Date
  writingPrompt: {
    title: string
    image: string
    description: string
    writingType: string
  },
  zookeepers: {
    _id: string
  }[]
  dismiss: () => void
}

const UpdateWritingAssignment = (props: IProps) => {
  const { onUpdateAssignmentGroup } = useAssignmentsState()

  const onSubmit = async (_id: string, data) => {
    await onUpdateAssignmentGroup(_id, data)
    props.dismiss()
  }

  return <CreateWritingAssignmnent
    editing
    _id={props._id}
    formData={{
      dueDate: props.dueDate,
      writingPrompt: props.writingPrompt,
      zookeepers: props.zookeepers,
    }}
    dismiss={props.dismiss}
    onSubmit={onSubmit}
  />
}

export default UpdateWritingAssignment
