import React from 'react'
import styled from 'styled-components'
import Nav from './components/Nav'
import StarTutoringAd from './components/StarTutoringAd'
import { AssignmentsPageStateProvider } from './index.state'
import Assignments from './pages/Assignments'
import Lessons from './pages/Lessons'
import Writing from './pages/Writing'
import useNav, { TABS } from './useNav'

const Content = styled.div`
  color: #fff;
`

const AssignmentsPage = () => {
  const { tab } = useNav()
  return (
    <AssignmentsPageStateProvider>
      <div>
        <Nav />
        <StarTutoringAd />
        <Content>
          {tab === TABS.ASSIGNMENTS && <Assignments />}
          {tab === TABS.LESSONS && <Lessons />}
          {tab === TABS.WRITING && <Writing />}
        </Content>
      </div>
    </AssignmentsPageStateProvider>
  )
}

export default AssignmentsPage
